<template>
    <div class="card">
        <div class="card-header">
            {{ $t('crud.users._create') }}
        </div>
        <div class="card-body">
            <users-form-component @submit="create" :record="record"></users-form-component>
        </div>
    </div>
</template>

<script>
    import {functions, config} from '../crud/mixin_config';
    import UsersFormComponent from "./Form";

    export default {
        name: 'users-create-component',
        mixins: [functions],
        components: {UsersFormComponent},
        data() {
            return {
                crud: config('users'),
                record: {
                    // Empty model.
                }
            };
        }
    }
</script>
